import React from "react";
import { useTranslation } from "react-i18next";
import "./Preferences.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function Version() {
  const { t, i18n } = useTranslation();
  const releaseNotes = [
    {
      version: "24.3.1",
      date: "18.09.24",
      description:
        "Anpassung Feedback-Anzeige",
    },
    {
      version: "24.3",
      date: "03.09.24",
      description:
        "Neuer Menüpunkt Materialien (Beta)",
    },
    {
      version: "24.2",
      date: "03.04.24",
      description:
        "Menüanpassung Mobile; Case insensitive für Mailadresse bei Login",
    },
    {
      version: "24.1",
      date: "29.02.24",
      description: "Fixes",
    },
    {
      version: "23.0.4",
      date: "25.10.23",
      description: "Programm als Slideshow; Mein Physiotherapeut",
    },
    {
      version: "23.0.3",
      date: "17.10.23",
      description: "Leichte Anpassungen Layout",
    },
    {
      version: "23.0.2",
      date: "29.03.23",
      description: "Diverse Bugfixes",
    },
    {
      version: "23.0.1",
      date: "01.03.23",
      description:
        "Release mit Standard-Features aus der Version 22 mit neu überarbeitetem Design",
    },
  ];
  return (
    <TableContainer>
      <Table className="version-table" aria-label="Release Notes">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Version</b>
            </TableCell>
            <TableCell>
              <b>Datum</b>
            </TableCell>
            <TableCell>
              <b>Kurzbeschreibung</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {releaseNotes.map((note) => (
            <TableRow key={note.version}>
              <TableCell component="th" scope="row">
                {note.version}
              </TableCell>
              <TableCell>{note.date}</TableCell>
              <TableCell>
                {" "}
                <ul>
                  {note.description.split(";").map((item) => (
                    <li key={item.trim()}>{item.trim()}</li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
